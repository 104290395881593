@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&family=Ubuntu:wght@300&display=swap');

/*------Main-Body-------*/

body {
  /* background-image: url("./images/5591276.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; */
  font-family: 'Ubuntu', sans-serif;
}

h1, h2 {
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 1rem;
  /* font-variant: all-petite-caps; */
  font-family: 'Ubuntu', sans-serif;

}

.section-title {
  background-color: whitesmoke;
  box-shadow: grey;
  /* TODO: improve shadow */
}

/*------Navbar-------*/

#navBar {
  padding: 0.5rem;
  font-size: 1rem;
  font-family: 'Ubuntu', sans-serif;
  display: flex;
}


/*------Home-------*/


#sociallink {
  margin-right: auto;
  margin-left: auto;
  display: none;
  font-size: 2.5rem;
  position: absolute;
  top: 8rem;
  left: 7rem;
  z-index: 100;
}

#sociallink svg:hover {
  fill: #eb851e;
}

#title-container h1 {
  overflow: hidden;
  border-right: .02em solid rgb(8, 8, 8);
  padding: 0;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  font-weight: bold;
  letter-spacing: .05em;
  text-shadow: white;
  animation:
    typing 2.5s forwards steps(100, end),
    blink-caret .75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 18rem;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent
  }

  50% {
    border-color: rgb(0, 0, 0);
  }
}

#title-container h4 {
  text-align: center;
  color: black;
  font-family: 'Ubuntu', sans-serif;
  text-shadow: white;
  animation-delay: 15s;
  opacity: 0;
  animation: fadeIn linear 5s;
  -webkit-animation: fadeIn linear 5s;
  -webkit-animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation: fadeIn linear 5s;
  -moz-animation-fill-mode: forwards;
  -o-animation: fadeIn linear 5s;
  -o-animation-fill-mode: forwards;
  -ms-animation: fadeIn linear 5s;
  -ms-animation-fill-mode: forwards;

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


#home {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  backface-visibility: hidden;
  padding-top: 2rem;
  height: 100vh;
  animation: bgAppear 6s ease 1 0s;
  animation-timing-function: ease-in-out;
  background-image: url('./images/bg/mountain.jpg');
}

@keyframes slideBg {
  0% {
    background-image: url('./images/Cover-cropped.png');
  }

  25% {
    background-image: url('./images/bg/aceCombat.jpg');
  }

  50% {
    background-image: url('./images/bg/astaYuno.jpg');
  }

  75% {
    background-image: url('./images/bg/tata2.jpg');
  }

  /* 100% {
    background-image: url('./images/bg/tata.jpeg');
  } */
}

@keyframes bgAppear {
  0% {
    background-image: url('./images/bg/plaincolor.png');
  }

  100% {
    background-image: url('./images/bg/mountain.jpg');
  }
}

#title-container {
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1rem;
  position: absolute;
  bottom: 5rem;
}



/*------About-Me-------*/
.aboutme-section {
  display: flexbox;
  background-image: url('./images/bg/dino.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.aboutmeinfo {
  text-align: center;
  font-size: 1.2rem;
  /* width: 30rem;
  text-align: left; */
  padding: 2rem;
  font-family: 'Ubuntu', sans-serif;
  color: white;
  justify-content: space-evenly;
}


#aboutmepicture {
  margin: auto;
  width: 300px;
}

#aboutmepicture img {
  width: 300px;
  justify-self: center;
  align-self: center;
  border-radius: .5rem;
}

#resume {
  border: none;
  background-color: #eb851e;
  color: white;
  font-size: 1.5rem;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 5px 5px 2px 1px rgba(78, 78, 214, 0.2);
}

#resume:hover {
  background-color: #cf471d;
  padding: 1.2rem;

}



/*------Skills-------*/
#styledimg h1 {
  background-color: whitesmoke;
  box-shadow: grey;
}

#styledimg #skillType {
  color: white;
}

/* Skills section */
#styledimg {
  background-image: url('./images/bg/headphone\ cloud.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#icon {
  width: 3.125rem;
  height: 3.25rem;
}

#icon:hover {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#skillsDiv {
  margin: auto;
  /* width: 50rem; */
  padding: 0.5rem;
}


#skillLevel {
  height: 3rem;
}

.biRightArrow:hover {
  transform: translateX(0.8rem);
  transition: 0.2s;
}

/*--------Projects--------*/
.card-projects {
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.753);
  padding: 2rem;
}

.card-projects img {
  width: 100%;
  padding: 2rem;

}

.card-projects p {
  text-align: center;
  font-size: large;
}

.projects-section {
  display: flexbox;
  background-image: url('./images/bg/aceCombat.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#previousButton, #nextButton {
  background: none;
  border: none;

}
#previousButton:hover,#nextButton :hover{
  color: black;
  
}
#nextButton {
  float: right;
}

/*------Contact-form-------*/

.contact-section {
  background-image: url('./images/bg/light.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.contact-form {
  color: Black;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.contactform-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#contactForm {
  /* align-items: center; */
  display: block;
  margin-left: auto;
  align-items: center;
  margin-right: auto;
  font-family: 'Ubuntu', sans-serif;
}

textarea {
  width: 80%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

input {
  width: 80%;
}

#contactbutton {
  margin-top: 1rem;
  background-color: #18a795;
}

/* -----To the top---- */
.to-the-top {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  background-color: none;
  background: none;
  border: none;
  float: right;

}

/*------Footer-------*/

footer {
  margin-left: auto;
  margin-right: auto;
  display: block;
  align-items: center;
  text-align: center;
  /* color: white; */
  background-color: white;
}

/*==================== RESPONSIVENESS ====================*/
@media screen and (min-width: 350px) {
  .contact-form {
    width: 80%;
  }

  .video-tutorial {
    position: absolute;

  }

  #previousButton:hover, #nextButton :hover {
    color: #eb851e;

  }
}

/* Screen size 1285 */
@media screen and (min-width:768px) {
  .contact-form {
    width: 50%;
  }

  .aboutme-container {
    grid-template-columns: repeat(2, 1fr);
  }

  #previousButton:hover, #nextButton :hover {
    color: #eb851e;

  }
}